<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="deleteAction">
        <feather-icon icon="Trash2Icon" class="mr-50" />
        <span class="align-middle">Eliminar</span>
      </b-button>
    </b-button-group>
  </div>
</template>
    
    <script>
import
{
  BButtonGroup,
  BButton,
  BModal,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,

  BCard,
  BCardTitle,
  BCardSubTitle
} from "bootstrap-vue";

import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import BrunaElementLoader from "@/views/brunacomponents/BrunaElementLoader.vue";

import useApiServices from "@/services/useApiServices.js";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    AgGridTable,
    BrunaElementLoader,
    AgGridVue,

    BCard,
    BCardTitle,
    BCardSubTitle,
    ToastificationContent

  },

  directives: {},

  data: function ()
  {
    return {
      gridTable: null,
      cellValue: null,

    };
  },
  beforeMount()
  {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);

    console.log(this.cellValue)
  },
  methods: {

    deleteAction()
    {

      useApiServices.postLineasDelete({ "id": this.cellValue.data.id })

        .then((response) =>
        {

          console.log(response)

          let route = [];
          let purge = true;
          this.params.api.refreshServerSideStore({ route: route, purge: purge });


          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Aviso`,
              icon: "UploadIcon",
              variant: "success",
              html: `La linea se ha eliminado exitosamente.`,
            },
          });
        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al eliminar la linea</b>.`,
            },
          });
        });


    },



    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },




    getValueToDisplay(params)
    {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    },
  },
};
    </script>
    
    <style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
    