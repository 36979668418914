<template>
  <section>
    <BrunaForm
      ref="nuevaLineaRef"
      v-bind:fields="this.nueva_linea.fields"
      :card="this.nueva_linea.card"
      @change="nuevaLineaChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import Vue from "vue";

import {
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";

import useApiServices from "@/services/useApiServices.js";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    let capacidades_mensuales = [];

    for (let mes = 1; mes <= 12; mes++) {
      capacidades_mensuales.push({
        label: "Capacidad M" + mes,
        type: "input",
        id: "capacidad_m" + mes,
        rules: "required|numeric2",
        tooltip: null,
        format: {
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
        },
        value: null,
      });

      /*
      capacidades_mensuales.push(

        {
          label: `Costo adicional / kg WFE M${mes} USD`,
          type: "input",
          id: 'costo_adicional_kg_wfe_usd_m' + mes,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2
          },
          value: null
        },
      );
    */
    }

    return {
      nueva_linea: {
        card: {
          title: "Crear Nueva Linea",
          subtitle: "",
          tooltip: "",
        },
        fields: [
          {
            label: "Estado",
            type: "select",
            id: "estado_linea",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,

            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "Activada", text: "Activada" },
              { value: "Pausada", text: "Pausada" },

            ],

          },
          {
            label: "Nombre",
            type: "input",
            id: "nombre_linea",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
          },
          {
            label: "Ubicacion",
            type: "input",
            id: "ubicacion",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
          },
          {
            label: "Costo Blending (USD/Ton)",
            type: "input",
            id: "costo_blending",
            rules: "required|numeric2",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
          },

          {
            label: "Velocidad Planta (Ton/Hr)",
            type: "input",
            id: "velocidad_planta",
            rules: "required|numeric2",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
          },

          {
            label: "Horas de funcionamiento",
            type: "input",
            id: "hora_funcionamiento",
            rules: "required|numeric2",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
          },

          {
            label: "Holgura Proteina %",
            type: "input",
            // placeholder:"Example!",
            id: "proteina",
            rules: "required|numeric2",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
            show: true,
          },
          {
            label: "Holgura Otros %",
            type: "input",
            // placeholder:"Example!",
            id: "otros",
            rules: "required|numeric2",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
            show: true,
          },
          {
            label: "Tolerancia Contratos %",
            type: "input",
            // placeholder:"Example!",
            id: "tolerancia",
            rules: "required|numeric2",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: null,
            show: true,
          }

        ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
  },

  created() {},

  mounted() {},

  methods: {
    formatData() {
      return {
        nueva_linea: this.nueva_linea.fields.reduce((acumulador, field) => {
          acumulador[field.id] = field.value;
          return acumulador;
        }, {}),
      };
    },

    createNuevaLinea() {
      useApiServices
        .postLineasCreate(this.formatData(), this)
        .then((response) => {
          this.$emit("nueva-linea-agregada");

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `La linea se ha creado exitosamente.`,
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar la nueva linea</b>.`,
            },
          });
        });
    },

    save() {
      console.log(JSON.stringify(this.formatData(), null, 2));

      let self = this;

      Promise.all([this.$refs.nuevaLineaRef.validate()]).then(
        ([nuevaLineaValid]) => {
          if (nuevaLineaValid) {
            self.createNuevaLinea();
          }

          //console.log({ demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid })
        }
      );
    },

    findFieldByName(fields, name) {
      return fields.find((field) => field.id == name);
    },

    nuevaLineaChange(data) {},
  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>